import React, {useEffect, useState} from 'react';

export const Histories = () => {
    const refresh = () => window.location.reload();

    let theme = localStorage.getItem('COMEAZY LOGISTICS-Theme');
    const [currentTheme, setCurrentTheme] = useState(theme);

      useEffect(() => {
        if (document.readyState === "complete") {
            let language = document.getElementsByClassName('goog-te-combo');
            language[0].classList.add("bg-theme");
            language[0].classList.add("color-theme");
        }
        
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('COMEAZY LOGISTICS-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark-mode')
                }else{
                   setCurrentTheme('light-mode') 
                }
               
            }             
       }, false);
      }, []);

      return (
          <div>
                {currentTheme === "dark-mode"
                ?
                    <div className="icon bg-theme color-theme rounded-circle ">
                        <img style={{marginTop: "10px"}} onClick={refresh} src='https://res.cloudinary.com/dzxyvdq14/image/upload/w_110,h_30,c_fit/comeazyitaliv.png' />
                    </div>
                :
                    <div className="icon bg-theme color-theme rounded-circle ">
                    <img style={{marginTop: "10px"}} onClick={refresh} src='https://res.cloudinary.com/dzxyvdq14/image/upload/w_110,h_30,c_fit/comeazy-logistics.png'/>
                    </div>
                }
          </div>
            
      )
}






